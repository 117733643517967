import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { Map } from 'immutable'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import MaterialMarkdown from './MaterialMarkdown'
import {
  RECIPE_BY_NICKNAME,
} from '../constants'
import {
  getPath,
  makeHash,
} from '../macros'


const useStyles = makeStyles(theme => ({
  pageTitle: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '3rem',
    },
  },
  tableOfContents: {
    fontSize: '1.25rem',
  },
  sectionTitle: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.5rem',
    },
  },
  sectionClosed: {
    display: 'none',
  },
  anchorLink: {
    position: 'relative',
    top: '-5rem',
  },
  suggestionLink: {
    marginTop: '3.5rem',
  },
}))


function TableOfContentsBlock(props) {
  const classes = useStyles()
  const {
    sectionByName,
    isOpenBySectionName,
    setIsOpenBySectionName,
  } = props
  const contentNames = sectionByName.keySeq().filter(_ => _)
  if (contentNames.count() < 2) {
    return ''
  }
  return contentNames.map((contentName, i) => (
    <Link
      href={'#' + makeHash(contentName)}
      onClick={() => setIsOpenBySectionName(
        isOpenBySectionName.merge({[contentName]: true}))}
      key={i}
    >
      <Typography
        variant='body1'
        component='p'
        align='center'
        className={classes.tableOfContents}
      >
        {contentName}
      </Typography>
    </Link>
  ))
}


function SectionTitle(props) {
  const classes = useStyles()
  const {
    sectionName,
    isOpenBySectionName,
    setIsOpenBySectionName,
  } = props
  if (!sectionName) {
    return ''
  }
  return (
    <>
      <Link
        id={makeHash(sectionName)}
        className={classes.anchorLink}
        children=''
      />
      <Link
        onClick={() => setIsOpenBySectionName(
          isOpenBySectionName.merge({
            [sectionName]: !isOpenBySectionName.get(sectionName)}))
        }
      >
        <Typography
          variant='h3'
          align='center'
          className={classes.sectionTitle}
          gutterBottom
        >
          {sectionName}
        </Typography>
      </Link>
    </>
  )
}


function SectionBody(props) {
  const classes = useStyles()
  const {
    sectionName,
    sectionText,
    isOpenBySectionName,
  } = props
  return (
    <div
      className={clsx({
        [classes.sectionClosed]: !isOpenBySectionName.get(sectionName),
      })}
    >
      <MaterialMarkdown>
        {sectionText}
      </MaterialMarkdown>
    </div>
  )
}


export default function RecipeWindow(props) {
  const classes = useStyles()
  const [isOpenBySectionName, setIsOpenBySectionName] = useState(Map({
    '': true,
    'Abstract': true,
    'Narrative': true,
    'Current Walkthrough': true,
    'Upcoming Walkthrough': false,
  }))
  const { location } = props
  const path = getPath(location)
  const pathParts = path.split('/')
  const nickname = pathParts[2]
  const recipe = RECIPE_BY_NICKNAME.get(nickname)
  const recipeName = recipe.get('name')
  const sectionByName = recipe.get('sections').filter(_ => _)
  const suggestionUrl = recipe.get('suggestionUrl')

  useEffect(() => {
    document.title = recipeName ? recipeName + ' Recipe' : '?'
  }, [recipeName])

  return (
    <>
      <Typography
        variant='h2'
        align='center'
        className={classes.pageTitle}
        gutterBottom
      >
        {recipe.get('name')} Recipe
      </Typography>

      <Grid container justify='center'>
        <Grid item lg={6} md={9} sm={12}>

          <TableOfContentsBlock
            sectionByName={sectionByName}
            isOpenBySectionName={isOpenBySectionName}
            setIsOpenBySectionName={setIsOpenBySectionName}
          />

      {sectionByName.entrySeq().map(([k, v], i) => (
        <div key={i}>
          <SectionTitle
            sectionName={k}
            isOpenBySectionName={isOpenBySectionName}
            setIsOpenBySectionName={setIsOpenBySectionName}
          />
          <SectionBody
            sectionName={k}
            sectionText={v}
            isOpenBySectionName={isOpenBySectionName}
          />
        </div>
      ))}

        {suggestionUrl &&
          <Link href={suggestionUrl}>
            <Typography
              variant='h4'
              align='center'
              className={classes.suggestionLink}
              gutterBottom
            >
              Make a Suggestion
            </Typography>
          </Link>
        }

        </Grid>
      </Grid>
    </>
  )
}
