import { combineReducers } from 'redux-immutable'
import reduceReducers from 'reduce-reducers'
import app from './app'


const reduceHorizontally = combineReducers({
  app,
})


const reduceVertically = (state, action) => {
  switch (action.type) {
    default:
      return state
  }
}


export default reduceReducers(
  reduceHorizontally,
  reduceVertically)
