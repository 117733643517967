import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import LinkCard from './LinkCard'


const useStyles = makeStyles(theme => ({
  grid: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}))


export default function CardGrid(props) {
  const classes = useStyles()
  const {
    items,
    itemNameColor,
    path,
  } = props

  return (
    <Grid
      container
      spacing={3}
      justify='space-evenly'
      className={classes.grid}
    >
    {items.map(item => {
      const itemId = item.get('id')
      const itemNickname = item.get('nickname')
      const itemName = item.get('name')
      const itemImageUrl = item.get('imageUrl')
      const itemDescription = item.get('description')
      const itemPath = path + '/' + itemNickname
      return (
        <Grid item md={4} sm={6} xs={12} key={itemId}>
          <LinkCard
            title={itemName}
            titleColor={itemNameColor}
            imageUrl={itemImageUrl}
            text={itemDescription}
            LinkProps={{
              component: RouterLink,
              to: itemPath,
            }}
          />
        </Grid>
      )
    })}
    </Grid>
  )
}
