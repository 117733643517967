import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Markdown from 'markdown-to-jsx'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import { makeHash } from '../macros'


const useStyles = makeStyles(theme => ({
  anchorLink: {
    position: 'relative',
    top: '-5rem',
  },
  text: {
    fontSize: '1.25rem',
    marginTop: '1.75rem',
    marginBottom: '1.75rem',
  },
  code: {
    fontSize: '1.25rem',
    backgroundColor: '#38ACEC',
    color: 'white',
  },
}))


function SmartLink(props) {
  const { href } = props
  let extraProps = {}

  if (href.startsWith('//')) {
    extraProps.target = '_blank'
    extraProps.rel = 'noopener'
  }

  return <Link
    {...extraProps}
    {...props}
  />
}


function SectionTitle(props) {
  const classes = useStyles()
  const { variant, children } = props
  const hash = makeHash(children.join(' '))
  return (
    <>
      <Link
        id={hash}
        className={classes.anchorLink}
        children=''
      />

      <Typography
        variant={variant}
        align='center'
        className={classes.sectionTitle}
        gutterBottom
      >
        {children}
      </Typography>
    </>
  )
}


function BodyText(props) {
  const classes = useStyles()
  const { component, children } = props
  const className = component === 'code' ? classes.code : classes.text
  return (
    <>
      <Typography
        variant='body1'
        component={component}
        className={className}
      >
        {children}
      </Typography>
    </>
  )
}


export default function MaterialMarkdown(props) {
  return (
    <Markdown
      options={{
        forceBlock: true,
        overrides: {
          a: SmartLink,
          h2: {component: SectionTitle, props: {variant: 'h4'}},
          p: {component: BodyText, props: {component: 'p'}},
          ol: {component: BodyText, props: {component: 'ol'}},
          ul: {component: BodyText, props: {component: 'ul'}},
          code: {component: BodyText, props: {component: 'code'}},
        },
      }}
      {...props}
    />
  )
}
