import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import Divider from '@material-ui/core/Divider'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Chip from '@material-ui/core/Chip'
// import CardGrid from './CardGrid'
import {
  REQUESTS,
} from '../constants'


const useStyles = makeStyles(theme => ({
  chip: {
    marginLeft: theme.spacing(1),
  },
  footer: {
    marginTop: theme.spacing(3),
  },
}))


export default function RequestsWindow() {
  const classes = useStyles()
  const [ isRequestDialogOpen, setIsRequestDialogOpen ] = useState(false)

  function openRequestDialog() {
    setIsRequestDialogOpen(true)
  }

  function closeRequestDialog() {
    setIsRequestDialogOpen(false)
  }

  useEffect(() => {
    document.title = 'Tool Requests'
  }, [])

  return (
    <>

      <Typography variant='h2' align='center' gutterBottom>
        Tool Requests
      </Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align='right'>Tags</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
    {
      REQUESTS.sortBy(_ => _.get('name')).map(request => {
        const requestId = request.get('id')
        const requestName = request.get('name')
        const requestUrl = request.get('url')
        const requestTags = request.get('tags')
        return (
          <TableRow key={requestId}>
            <TableCell>
            {requestUrl ?
              <Link component='a' href={requestUrl}>{requestName}</Link> :
              requestName
            }
            </TableCell>
            <TableCell align='right'>
            {requestTags.map((tag, index) => (
              <Chip
                label={tag}
                className={classes.chip}
                key={index}
              />
            ))}
            </TableCell>
          </TableRow>
        )
      })
    }
        </TableBody>
      </Table>

      <Divider />

      <Link
        underline='hover'
        color='secondary'
        onClick={openRequestDialog}
      >
        <Typography
          variant='h4'
          align='center'
          className={classes.footer}
        >
          Request a Custom Tool
        </Typography>
      </Link>

      <Dialog
        open={isRequestDialogOpen}
        onClose={closeRequestDialog}
      >
        <DialogTitle>
          Request a Custom Tool
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            Please message contact@crosscompute.com with a detailed
            description of the desired input arguments and output
            properties of the tool. Include example input and output
            files if possible.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={closeRequestDialog} color='primary'>
            Great!
          </Button>
        </DialogActions>
      </Dialog>

    </>
  )
}
