import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import CardGrid from './CardGrid'
import {
  COOKBOOK_BY_NICKNAME,
} from '../constants'
import {
  getPath,
} from '../macros'


export default function CookbookWindow(props) {
  const {
    location,
  } = props

  const path = getPath(location)
  const pathParts = path.split('/')
  const nickname = pathParts[2]
  const cookbook = COOKBOOK_BY_NICKNAME.get(nickname)
  const recipes = cookbook.get('recipes')

  let title
  if (cookbook) {
    const cookbookName = cookbook.get('name')
    title = cookbookName + ' Cookbook'
  } else {
    title = '?'
  }

  useEffect(() => {
    document.title = title
  }, [title])

  return (
    <>
      <Typography variant='h2' align='center' gutterBottom>
        {title}
      </Typography>

    {recipes.size ?
      <CardGrid items={recipes} itemNameColor='primary' path='/recipes' />
      :
      <>
      <Typography variant='h4' align='center' gutterBottom>
        Coming Soon!
      </Typography>
      <Typography align='center'>
        <Link href='//forum.crosscompute.com/c/requests'>In the meantime, you can browse tool requests or request a custom tool.</Link>
      </Typography>
      </>
    }
    </>
  )
}
