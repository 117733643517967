import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import CardGrid from './CardGrid'
import {
  RECIPES,
} from '../constants'


export default function RecipesWindow() {
  const recipes = RECIPES
    
  useEffect(() => {
    document.title = 'Algorithm Recipes'
  }, [])

  return (
    <>
      <Typography
        variant='h2'
        align='center'
        gutterBottom
      >
        Algorithm Recipes
      </Typography>

      <CardGrid
        items={recipes}
        itemNameColor='primary'
        path='/recipes'
      />
    </>
  )
}
