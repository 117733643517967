import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'


const useStyles = makeStyles({
  color1: {fill: '#939598'},
  color2: {fill: '#F63806'},
  color3: {fill: '#148ED5'},
  color4: {fill: '#D43105'},
  color5: {fill: '#17A2F5'},
  color6: {fill: '#284454'},
})


export default function Logo() {
  const classes = useStyles()
  return (
    <SvgIcon viewBox='0 0 48 48'>
      <g className={classes.color1}>
        <path d='M29.1,11.9c1.2,0.5,2.4,1.2,3.4,2l5.4-8.1c-2.1-1.6-4.4-2.8-7-3.6c-0.7-0.2-1.4,0.2-1.5,1 l-1,7.5C28.3,11.2,28.6,11.7,29.1,11.9z'/>
        <path d='M29.1,37.2c-0.5,0.2-0.8,0.7-0.7,1.2l1,7.5c0.1,0.7,0.8,1.2,1.5,1c2.5-0.8,4.9-2,7-3.6 l-5.4-8.1C31.5,36,30.3,36.7,29.1,37.2z'/>
        <path d='M18.8,37.2c-1.2-0.5-2.4-1.2-3.4-2l-5.4,8.1c2.1,1.6,4.4,2.8,7,3.6c0.7,0.2,1.4-0.2,1.5-1 l1-7.5C19.6,37.9,19.3,37.4,18.8,37.2z'/>
        <path d='M18.5,3.1c-0.1-0.7-0.8-1.2-1.5-1c-2.5,0.8-4.9,2-7,3.6l5.4,8.1c1-0.8,2.1-1.5,3.4-2 c0.5-0.2,0.8-0.7,0.7-1.2L18.5,3.1z'/>
        <path d='M26.3,2.9h-4.6c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6h4.6c0.3,0,0.6-0.3,0.6-0.6 S26.7,2.9,26.3,2.9z'/>
        <path d='M26.6,6.6C26.6,6.3,26.3,6,26,6h-3.9c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6H26 C26.3,7.2,26.6,7,26.6,6.6z'/>
        <path d='M25.6,9.1h-3.2c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h3.2c0.3,0,0.6-0.3,0.6-0.6 C26.3,9.4,26,9.1,25.6,9.1z'/>
        <path d='M26.3,45h-4.6c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h4.6c0.3,0,0.6-0.3,0.6-0.6 C26.9,45.3,26.7,45,26.3,45z'/>
        <path d='M22.1,43.2H26c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6h-3.9c-0.3,0-0.6,0.3-0.6,0.6 C21.5,42.9,21.8,43.2,22.1,43.2z'/>
        <path d='M25.6,38.9h-3.2c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6h3.2c0.3,0,0.6-0.3,0.6-0.6 S26,38.9,25.6,38.9z'/>
      </g>

      <path className={classes.color2} d='M37.9,5.7l-5.4,8.1c3.1,2.5,5.2,6.4,5.2,10.7c0,4.3-2,8.2-5.2,10.7l5.4,8.1 c5.7-4.3,9.5-11.1,9.5-18.8C47.4,16.8,43.6,10,37.9,5.7z'/>
      <path className={classes.color3} d='M10.1,43.3l5.4-8.1c-3.1-2.5-5.2-6.4-5.2-10.7c0-4.3,2-8.2,5.2-10.7l-5.4-8.1 C4.3,10,0.6,16.8,0.6,24.5C0.6,32.2,4.3,39,10.1,43.3z'/>
      <g className={classes.color4}>
        <path d='M40.2,37.3c-0.3-0.3-0.8-0.2-1,0.1c-0.2,0.3-0.5,0.6-0.8,0.9c-0.3,0.3-0.2,0.8,0.1,1 c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.2c0.3-0.3,0.6-0.6,0.8-1C40.5,38,40.5,37.6,40.2,37.3z'/>
        <path d='M44.4,18.4c-0.1-0.4-0.5-0.6-0.9-0.5c-0.4,0.1-0.6,0.5-0.5,0.9c0.5,1.8,0.7,3.7,0.7,5.5 c0,4-1.1,7.8-3.2,11.2c-0.2,0.3-0.1,0.8,0.2,1c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.5-0.1,0.6-0.3c2.3-3.6,3.4-7.7,3.4-12 C45.2,22.3,44.9,20.3,44.4,18.4z'/>
      </g>
      <g className={classes.color5}>
        <path d='M9.4,9.7C9.2,9.6,9.1,9.5,8.9,9.5c-0.2,0-0.4,0.1-0.5,0.2c-0.3,0.3-0.6,0.6-0.8,1 c-0.3,0.3-0.2,0.8,0.1,1c0.3,0.3,0.8,0.2,1-0.1c0.2-0.3,0.5-0.6,0.8-0.9C9.7,10.4,9.7,10,9.4,9.7z'/>
        <path d='M7,12.6c-0.1-0.1-0.3-0.1-0.4-0.1c-0.2,0-0.5,0.1-0.6,0.3c-2.3,3.6-3.4,7.7-3.4,12 c0,2,0.3,4,0.8,5.9c0.1,0.4,0.5,0.6,0.9,0.5c0.4-0.1,0.6-0.5,0.5-0.9C4.3,28.5,4,26.6,4,24.8c0-4,1.1-7.8,3.2-11.2 C7.5,13.3,7.4,12.8,7,12.6z'/>
      </g>
      <g className={classes.color6}>
        <path d='M45.6,13.5c-1.6-3-3.8-5.8-6.5-7.9l0,0l-1.4-1l0,0c-2-1.4-4.2-2.4-6.5-3.2c-0.6-0.2-1.2-0.1-1.7,0.2 C29,1.9,28.7,2.4,28.6,3l-1,7.5c-0.1,0.9,0.4,1.8,1.2,2.1c1.1,0.5,2.2,1.1,3.2,1.9c3.1,2.5,4.8,6.1,4.9,10c0,3.9-1.8,7.6-4.9,10 c-1,0.8-2,1.4-3.2,1.9c-0.9,0.3-1.4,1.2-1.2,2.1l1,7.5c0.1,0.6,0.4,1.1,0.9,1.4c0.3,0.2,0.7,0.3,1.1,0.3c0.2,0,0.4,0,0.6-0.1 c2.3-0.7,4.5-1.8,6.5-3.2l0,0l1.4-1l0,0c2.7-2.1,4.9-4.9,6.5-7.9c1.8-3.4,2.7-7.2,2.7-11.1C48.2,20.6,47.3,16.9,45.6,13.5z M36.7,43.1c-1.9,1.3-3.9,2.3-6.1,2.9c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1-0.1-0.1-0.2l-1-7.5c0-0.1,0.1-0.3,0.2-0.3 c1-0.4,1.9-0.9,2.8-1.5L36.7,43.1z M36.7,6l-4.4,6.6c-0.9-0.6-1.8-1.1-2.8-1.5c-0.1-0.1-0.2-0.2-0.2-0.3l1-7.5 c0-0.1,0.1-0.2,0.1-0.2c0,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0C32.8,3.7,34.9,4.7,36.7,6z M38.5,24.5c0-4.2-1.8-8.1-4.9-10.9L38.1,7 c0.2,0.2,0.4,0.4,0.7,0.6l-3.8,5.4c-0.1,0.2-0.2,0.4-0.1,0.6c0,0.2,0.2,0.4,0.3,0.6c0.4,0.3,0.9,0.2,1.2-0.2L40,8.7 c0.2,0.2,0.3,0.3,0.5,0.5l-2.9,3.9c-0.3,0.4-0.2,0.9,0.2,1.2c0.4,0.3,0.9,0.2,1.2-0.2l2.7-3.6c0.1,0.2,0.3,0.3,0.4,0.5l-1.8,2.2 c-0.3,0.4-0.2,0.9,0.1,1.2c0.4,0.3,0.9,0.2,1.2-0.1l1.4-1.8c2.3,3.6,3.5,7.7,3.5,12c0,6.7-3.2,13.3-8.4,17.5l-4.5-6.7 C36.7,32.6,38.5,28.7,38.5,24.5z'/>
        <path d='M19.2,36.4C18,35.9,17,35.3,16,34.6c-3.1-2.5-4.8-6.1-4.9-10c0-3.9,1.8-7.6,4.9-10c1-0.8,2-1.4,3.2-1.9 c0.9-0.3,1.4-1.2,1.2-2.1l-1-7.5c-0.1-0.6-0.4-1.1-0.9-1.4c-0.5-0.3-1.1-0.4-1.7-0.2c-2.3,0.7-4.5,1.8-6.5,3.2l0,0l-1.4,1l0,0 C6.2,7.7,4,10.4,2.4,13.5c-1.8,3.4-2.7,7.2-2.7,11.1c0,3.9,0.9,7.6,2.7,11.1c1.6,3,3.8,5.8,6.5,7.9l0,0l1.4,1l0,0 c2,1.4,4.2,2.4,6.5,3.2c0.2,0.1,0.4,0.1,0.6,0.1c0.4,0,0.8-0.1,1.1-0.3c0.5-0.3,0.8-0.8,0.9-1.4l1-7.5C20.5,37.6,20,36.8,19.2,36.4z M17.7,3.3l1,7.5c0,0.1-0.1,0.3-0.2,0.3c-1,0.4-1.9,0.9-2.8,1.5L11.2,6c1.9-1.3,3.9-2.3,6.1-2.9c0.1,0,0.2,0,0.3,0 C17.6,3.1,17.7,3.1,17.7,3.3z M13,36.1c0.1-0.2,0.2-0.4,0.1-0.6c0-0.2-0.2-0.4-0.3-0.6c-0.2-0.1-0.4-0.2-0.6-0.1 c-0.2,0-0.4,0.2-0.6,0.3L8,40.3c-0.2-0.2-0.3-0.3-0.5-0.5l2.9-3.9c0.3-0.4,0.2-0.9-0.2-1.2c-0.2-0.1-0.4-0.2-0.6-0.2 c-0.2,0-0.4,0.2-0.6,0.3l-2.7,3.6C6.2,38.3,6.1,38.2,6,38l1.7-2.2c0.1-0.2,0.2-0.4,0.2-0.6c0-0.2-0.1-0.4-0.3-0.6 c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.6,0.3l-1.4,1.8c-2.3-3.6-3.5-7.7-3.5-12c0-6.7,3.2-13.3,8.4-17.5l4.5,6.7 c-3.1,2.8-4.9,6.7-4.9,10.9c0,4.2,1.8,8.1,4.9,10.9l-4.5,6.7c-0.2-0.2-0.4-0.4-0.7-0.6L13,36.1z M18.7,38.3l-1,7.5 c0,0.1-0.1,0.2-0.1,0.2c-0.1,0-0.1,0.1-0.3,0c-2.2-0.7-4.2-1.7-6.1-2.9l4.4-6.6c0.9,0.6,1.8,1.1,2.8,1.5 C18.7,38,18.7,38.2,18.7,38.3z'/>
        <path d='M33,24.6c0-5-4-9-9-9s-9,4-9,9s4,9,9,9S33,29.5,33,24.6z M23.2,32.1c-3.8-0.4-6.7-3.6-6.7-7.5 c0-0.7,0.1-1.3,0.2-1.9l4.3,4.6c0,0,0,1.3,0,1.6c0,0.4,0.6,0.9,1,0.9h0.9c0.1,0,0.2,0.1,0.2,0.2V32.1z M29.5,28.9 c-0.1-0.3-0.3-0.5-0.6-0.5l-1.8,0v-2.4c0-0.7-0.5-1.2-1.2-1.2l-5.7-0.1v-2.1h1.5c0.8,0,1.5-0.7,1.5-1.5v-1.7H26c0,0,1.1-0.6,1.6-1.4 c2.4,1.3,4,3.8,4,6.7c0,1.9-0.7,3.7-1.9,5L29.5,28.9z'/>
      </g>
    </SvgIcon>
  )
}
