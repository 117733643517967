import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import Divider from '@material-ui/core/Divider'
import CardGrid from './CardGrid'
import {
  COOKBOOKS,
} from '../constants'


const useStyles = makeStyles(theme => ({
  footer: {
    marginTop: theme.spacing(3),
  },
}))


export default function CookbooksWindow(props) {
  const classes = useStyles()
  const cookbooks = COOKBOOKS

  useEffect(() => {
    document.title = 'Algorithm Cookbooks'
  }, [])

  return (
    <>

      <Typography
        variant='h2'
        align='center'
      >
        Algorithm Cookbooks
      </Typography>

      <CardGrid
        items={cookbooks}
        itemNameColor='primary'
        path='/cookbooks'
      />

      <Divider />

      <Link
        underline='hover'
        color='primary'
        component='a'
        href='//crosscompute.com/explore'
      >
        <Typography
          variant='h4'
          align='center'
          className={classes.footer}
        >
          Explore More Content Contributed by Creators
        </Typography>
      </Link>

    </>
  )
}
