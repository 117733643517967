import { Map } from 'immutable'
import {
  CLOSE_INFORMATION_DRAWER,
  CLOSE_NAVIGATION_DRAWER,
  OPEN_INFORMATION_DRAWER,
  OPEN_NAVIGATION_DRAWER,
} from '../constants'


const initialState = Map({
  isNavigationDrawerOpen: false,
  isInformationDrawerOpen: false,
})


const app = (state=initialState, action) => {
  switch (action.type) {
    case OPEN_NAVIGATION_DRAWER: {
      return state.merge({
        isNavigationDrawerOpen: true,
        // isInformationDrawerOpen: false,
      })
    }
    case CLOSE_NAVIGATION_DRAWER: {
      return state.merge({
        isNavigationDrawerOpen: false,
      })
    }
    case OPEN_INFORMATION_DRAWER: {
      return state.merge({
        isInformationDrawerOpen: true,
        // isNavigationDrawerOpen: false,
      })
    }
    case CLOSE_INFORMATION_DRAWER: {
      return state.merge({
        isInformationDrawerOpen: false,
      })
    }
    default: {
      return state
    }
  }
}


export default app
