import { Map, fromJS } from 'immutable'
import cookbooks from './datasets/cookbooks.json'
import requests from './datasets/requests.json'


export const NAVIGATION_DRAWER_WIDTH = 192
export const INFORMATION_DRAWER_WIDTH = 768
export const RIGHT_DRAWER_MINIMUM_WIDTH = 384


export const TOOLTIP_DELAY = 500


export const OPEN_NAVIGATION_DRAWER = 'OPEN_NAVIGATION_DRAWER'
export const CLOSE_NAVIGATION_DRAWER = 'CLOSE_NAVIGATION_DRAWER'


export const OPEN_INFORMATION_DRAWER = 'OPEN_INFORMATION_DRAWER'
export const CLOSE_INFORMATION_DRAWER = 'CLOSE_INFORMATION_DRAWER'


export const COOKBOOKS = fromJS(cookbooks)
export const COOKBOOK_BY_NICKNAME = COOKBOOKS.reduce((
  cookbookByNickname,
  cookbook,
) => cookbookByNickname.set(
  cookbook.get('nickname'),
  cookbook,
), Map())


export const REQUESTS = fromJS(requests)


// !!!
const recipes = []
COOKBOOKS.valueSeq().forEach(cookbook => {
  cookbook.get('recipes').forEach(recipe => {
    recipes.push(recipe)
  })
})
export const RECIPES = fromJS(recipes)
export const RECIPE_BY_NICKNAME = RECIPES.reduce((
  recipeByNickname,
  recipe,
) => recipeByNickname.set(
  recipe.get('nickname'),
  recipe,
), Map())
