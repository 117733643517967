import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import CookbooksIcon from '@material-ui/icons/LibraryBooks'
import RequestsIcon from '@material-ui/icons/Inbox'
import LeftCloseIcon from '@material-ui/icons/ChevronLeft'
import ListItemLink from './ListItemLink'
import {
  CLOSE_NAVIGATION_DRAWER,
  NAVIGATION_DRAWER_WIDTH,
  TOOLTIP_DELAY,
} from '../constants'
import {
  getIsNavigationDrawerOpen,
} from '../selectors'


const useStyles = makeStyles(theme => ({
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: `0 ${theme.spacing(1)}px`,
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  drawerPaper: {
    width: NAVIGATION_DRAWER_WIDTH,
  },
}))


export default function NavigationDrawer() {
  const classes = useStyles()
  const dispatch = useDispatch()

  const isNavigationDrawerOpen = useSelector(getIsNavigationDrawerOpen)
  const closeNavigationDrawer = useCallback(() => dispatch({
    type: CLOSE_NAVIGATION_DRAWER,
  }), [dispatch])

  return (
    <Drawer
      anchor='left'
      classes={{
        paper: classes.drawerPaper,
      }}
      open={isNavigationDrawerOpen}
      onClose={closeNavigationDrawer}
    >
      <div className={classes.drawerHeader}>
        <Tooltip title='Close' enterDelay={TOOLTIP_DELAY}>
          <IconButton onClick={closeNavigationDrawer}>
            <LeftCloseIcon />
          </IconButton>
        </Tooltip>
      </div>
      <Divider />
      <List>
        <ListItemLink to='/cookbooks' text='Cookbooks' icon={<CookbooksIcon />} />
        <ListItemLink to='/requests' text='Requests' icon={<RequestsIcon />} />
      </List>
    </Drawer>
  )
}
