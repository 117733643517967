import React, {
  useCallback,
  // useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
// import SearchIcon from '@material-ui/icons/Search'
import Typography from '@material-ui/core/Typography'
// import InputBase from '@material-ui/core/InputBase'
import LogoIcon from '../icons/Logo-SmallFormat-20190613'
import {
  INFORMATION_DRAWER_WIDTH,
  OPEN_NAVIGATION_DRAWER,
  RIGHT_DRAWER_MINIMUM_WIDTH,
  TOOLTIP_DELAY,
} from '../constants'
import {
  getIsInformationDrawerOpen,
} from '../selectors'


const useStyles = makeStyles(theme => ({
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarWithInformation: {
    width: `calc(100% - ${INFORMATION_DRAWER_WIDTH}px)`,
    marginRight: INFORMATION_DRAWER_WIDTH,
    [theme.breakpoints.down('md')]: {
      width: `calc(100% - ${RIGHT_DRAWER_MINIMUM_WIDTH}px)`,
      marginRight: RIGHT_DRAWER_MINIMUM_WIDTH,
    },
  },
  title: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  searchButton: {
    marginLeft: theme.spacing(1),
  },
  vanish: {
    display: 'none',
  },
}))


export default function ApplicationBar() {
  const classes = useStyles()
  const dispatch = useDispatch()
  // const [isSearchInputOpen, setSearchInputOpen] = useState(false)

  const isInformationDrawerOpen = useSelector(getIsInformationDrawerOpen)
  const isDrawerOpen = isInformationDrawerOpen

  const openNavigationDrawer = useCallback(() => dispatch({
    type: OPEN_NAVIGATION_DRAWER,
  }), [dispatch])

  return (
    <AppBar
      color='default'
      className={clsx(classes.appBar, {
        [classes.appBarShift]: isDrawerOpen,
        [classes.appBarWithInformation]: isInformationDrawerOpen,
      })}
    >
      <Toolbar>

        <Tooltip title='Open Navigation' enterDelay={TOOLTIP_DELAY}>
          <IconButton
            edge='start'
            color='inherit'
            aria-label='Open Navigation'
            className={classes.menuButton}
            onClick={openNavigationDrawer}
          >
            <LogoIcon />
          </IconButton>
        </Tooltip>

      {/*
      {isSearchInputOpen ?
        <InputBase autoFocus fullWidth placeholder='Search' /> :
        <Typography variant='h6' color='inherit' className={classes.title}>
          CrossCompute
        </Typography>
      }

      <Tooltip title='Toggle Search' enterDelay={TOOLTIP_DELAY}>
        <IconButton
          edge='end'
          color='inherit'
          aria-label='Toggle Search'
          className={classes.searchButton}
          onClick={() => setSearchInputOpen(!isSearchInputOpen)}
        >
          <SearchIcon />
        </IconButton>
      </Tooltip>
      */}

        <Typography variant='h6' color='inherit' className={classes.title}>
          CrossCompute
        </Typography>

      </Toolbar>
    </AppBar>
  )
}
