import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardActionArea from '@material-ui/core/CardActionArea'
import Typography from '@material-ui/core/Typography'


const useStyles = makeStyles(theme => ({
  cardActionArea: {
    padding: theme.spacing(3),
  },
  cardDescription: {
    marginTop: theme.spacing(3),
  },
}))


export default function LinkCard(props) {
  const classes = useStyles()
  const {
    title,
    titleColor,
    imageUrl,
    text,
    LinkProps,
  } = props

  return (
    <Link underline='none' {...LinkProps}
    >
      <Card>
        <CardActionArea className={classes.cardActionArea}>

          <Typography
            color={titleColor}
            variant='h4'
            align='center'
            gutterBottom
          >
            {title}
          </Typography>

        {imageUrl &&
          <CardMedia component='img' src={imageUrl} />
        }

          <Typography
            variant='h5'
            align='center'
            className={classes.cardDescription}
          >
            {text}
          </Typography>

        </CardActionArea>
      </Card>
    </Link>
  )
}
