import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography'


export default function NotFoundWindow() {
  useEffect(() => {
    document.title = '?'
  }, [])

  return (
    <>
      <Typography variant='h6' align='center' paragraph>
        Whoops!
      </Typography>
      <Typography align='center' paragraph>
        We could not find the page you requested.
      </Typography>
    </>
  )
}
