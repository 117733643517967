import React from 'react'
// import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import CookbooksWindow from './CookbooksWindow'
import CookbookWindow from './CookbookWindow'
import RequestsWindow from './RequestsWindow'
import RecipesWindow from './RecipesWindow'
import RecipeWindow from './RecipeWindow'
import NotFoundWindow from './NotFoundWindow'
/*
import {
  INFORMATION_DRAWER_WIDTH,
  RIGHT_DRAWER_MINIMUM_WIDTH,
} from '../constants'
import {
  getIsInformationDrawerOpen,
} from '../selectors'
*/


const useStyles = makeStyles(theme => ({
  main: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    padding: theme.spacing(3),
  },
  mainShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  /*
  mainWithInformation: {
    marginRight: INFORMATION_DRAWER_WIDTH,
    [theme.breakpoints.down('md')]: {
      marginRight: RIGHT_DRAWER_MINIMUM_WIDTH,
    },
  },
  */
}))


export default function Main() {
  const classes = useStyles()

  /*
  const isInformationDrawerOpen = useSelector(getIsInformationDrawerOpen)
  const isDrawerOpen = isInformationDrawerOpen
  */

  return (
    <main className={clsx(classes.main, {
      /*
      [classes.mainShift]: isDrawerOpen,
      [classes.mainWithInformation]: isInformationDrawerOpen,
      */
    })}>
      <Switch>
        <Route exact path='/cookbooks' component={CookbooksWindow} />
        <Route exact path='/recipes' component={RecipesWindow} />
        <Route exact path='/requests' component={RequestsWindow} />

        <Route path='/cookbooks/' component={CookbookWindow} />
        <Route path='/recipes/' component={RecipeWindow} />

        <Route component={NotFoundWindow} />
      </Switch>
    </main>
  )
}
